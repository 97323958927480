import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Directive({
    selector: '[formControlInvalid]',
    standalone: true
})
export class FormControlInvalidDirective implements OnInit, OnDestroy {
    private hasView = false;
    private subscription: Subscription | null = null;

    @Input() set formControlInvalid(control: AbstractControl | null) {
        if (control && control !== this.control) {
            this.control = control;
            this.setUpSubscription();
        }
    }

    @Input() set formControlInvalidError(errorKey: string) {
        this.errorKey = errorKey;
        this.updateView();
    }

    private control: AbstractControl | null = null;
    private errorKey: string | null = null;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    ngOnInit() {
        if (this.control) {
            this.setUpSubscription();
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private setUpSubscription() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        if (this.control) {
            this.subscription = merge(
                this.control.valueChanges,
                this.control.statusChanges
            ).pipe(
                startWith(null) // Emit initial value
            ).subscribe(() => {
                this.updateView();
            });
        }
    }

    private updateView() {
        const invalid = this.checkInvalid();

        if (invalid && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!invalid && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    private checkInvalid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.errorKey) {
            return (this.control.touched || this.control.dirty) && this.control.hasError(this.errorKey);
        }

        return this.control.invalid && (this.control.dirty || this.control.touched);
    }
}
