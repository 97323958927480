import {Component, Input, input, signal} from '@angular/core';
import { NgClass } from "@angular/common";
import { IconType, NgIcon } from "@ng-icons/core";
import { AutoAnimateDirective } from "@knalgeel/pandora";
import { Router } from "@angular/router";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'app-search-nav-button',
    standalone: true,
    imports: [
        NgClass,
        NgIcon,
        AutoAnimateDirective,
        FaIconComponent
    ],
    templateUrl: './search-nav-button.component.html',
})
export class SearchNavButtonComponent {
    @Input() colorMode: 'light' | 'dark' = 'light';

    active = input<boolean>(false);
    icon = input.required<IconProp>();

    constructor(
        private readonly router: Router,
    ) {}

}
