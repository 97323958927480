<div class="flex rounded-4xl"
     [ngClass]="colorMode === 'light' ? 'bg-gray-50' : 'bg-gray-800'"
>
    <app-search-nav-button
        [colorMode]="colorMode"
        [icon]="faRightLeftLarge"
        [routerLink]="['/', 'vraag']"
    >
        <div text i18n="@@component.search-nav.button.demand">
            Vraag
        </div>
    </app-search-nav-button>

    <app-search-nav-button
        [colorMode]="colorMode"
        [icon]="faBars"
        [routerLink]="['/', 'aanbod']"
    >
        <div text i18n="@@component.search-nav.button.supply">
            Aanbod
        </div>
    </app-search-nav-button>

    <app-search-nav-button
        [colorMode]="colorMode"
        [icon]="faBuildingMagnifyingGlass"
        [routerLink]="['/', 'aanbod']"
    >
        <div text i18n="@@component.search-nav.button.supply">
            Bedrijven
        </div>
    </app-search-nav-button>
</div>
