import { Component, signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SearchService } from '../../../services/search.service';
import { DatePicker, DatePickerModule } from 'primeng/datepicker';
import { AutocompleteOffDirective } from '../../../../core/directives/autocomplete-off.directive';

@Component({
    selector: 'nav-search-supply-date-select',
    standalone: true,
    imports: [
        FormsModule,
        DatePickerModule,
        AutocompleteOffDirective
    ],
    templateUrl: './search-supply-date-select.component.html',
    styleUrl: './search-supply-date-select.component.scss'
})
export class SearchSupplyDateSelectComponent {
    @ViewChild('datepicker') datepicker!: DatePicker;

    minDate: Date = new Date();
    date = signal<Date | null>(null);
    dateFormat = signal('dd-mm-yy');
    placeholder = signal('Morgen');

    constructor(
        private readonly searchService: SearchService
    ) {
    }

    toggleDropdown() {
        this.datepicker.showOverlay();
    }

    onChangeDate(date: Date) {
        if (!date) {
            // Set to tomorrow
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            this.searchService.set('date', tomorrow.toISOString().split('T')[0]);

            return;
        }
        this.date.set(date);
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const formattedDate = utcDate.toISOString().split('T')[0];
        this.searchService.set('date', formattedDate);
    }
}
