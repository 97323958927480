<button
    class="h-12 px-5 rounded-4xl transition-all duration-250 ease-in-out"
    [ngClass]="[
        colorMode === 'light' ?
            active() ? 'bg-gray-800 text-white' : 'bg-gray-50 text-gray-800' :
            active() ? 'bg-white text-gray-900' : 'bg-gray-800 text-white'
    ]"
    (click)="active() && $event.stopPropagation()"
>
    <div autoAnimate class="flex items-center text-sm font-semibold h-full">
        <div class="flex items-center pr-2">
            <fa-icon
                [icon]="icon()"
                [ngClass]="active() ? 'text-black' : 'text-gray-300'"
                size="sm"
            ></fa-icon>
        </div>
        <ng-content select="[text]"/>
        @if (active()) {
            <div class="pl-4 h-full flex items-center">
                <ng-content select="[body]"/>
            </div>
        }
    </div>
</button>
