<div autoAnimate
     (clickOutside)="onClickOutside()"
>
    <button
        type="button"
        class="relative w-full cursor-default text-left text-gray-900 text-sm"
    >
        <div class="block">
            <input
                #input
                id="navbarCategorySearchInput"
                type="text"
                autocompleteOff
                [size]="search().length < 14 ? 18 : search().length + 4"
                class="w-full py-1.5 pr-1 outline-0 font-medium"
                [ngModel]="search()"
                (ngModelChange)="onSearchChange($event)"
                (focus)="onFocus()"
                placeholder="bijv. Rupskraan"
            />
        </div>
        <div class="absolute pointer-events-none inset-0 flex items-center justify-end">
            <ng-icon name="heroChevronUpDownMini" size="1em"/>
        </div>
    </button>
    <div class="z-10 absolute top-full -translate-x-4">
        @if (active()) {
            <div
                autoAnimate
                class="bg-white rounded-sm overflow-hidden shadow w-72 divide-y border"
            >
                @if (showHistory()) {
                    <div>
                        <h4 class="text-left pt-4 px-4 pb-2 text-gray-300 border-b border-gray-100 font-semibold">
                            Laatste zoekopdrachten ({{ history.length }})
                        </h4>
                        <ul class="divide-y font-normal">
                            @for (option of history; track option) {
                                <li
                                    (click)="onClickListItem(option, $event)"
                                    class="group px-4 py-2 hover:bg-gray-50 flex items-center justify-between cursor-pointer"
                                >
                                    <div
                                        class="search-category-select__list-item-title"
                                        [innerHTML]="option.category.name"
                                    ></div>
                                </li>
                            }
                            @if (history.length === 0) {
                                <li class="px-4 pt-2 pb-4 text-gray-300 font-normal">
                                    Geen zoekopdrachten
                                </li>
                            }
                        </ul>
                    </div>
                } @else {
                    <ul autoAnimate class="py-2 min-h-32">
                        @for (option of categories(); track option.id) {
                            <li
                                (click)="onClickListItem(option, $event)"
                                class="group px-4 py-2 hover:bg-gray-100 flex items-center justify-between cursor-pointer"
                                [class]="{ 'opacity-50': option.listedItemCount === 0 }"
                            >
                                <div class="search-category-select__list-item-title"
                                     [innerHTML]="option.category.name"></div>
                                <div class="text-xs">
                                    {{ option.listedItemCount }}
                                </div>
                            </li>
                        } @empty {
                            @if (!loading()) {
                                <li class="px-4 pt-2 pb-4 text-gray-300 font-normal">
                                    Geen categorieën gevonden voor deze zoekopdracht
                                </li>
                            }
                        }
                    </ul>
                }
            </div>
        }
    </div>
</div>
