<p-datePicker
    #datepicker
    autocompleteOff
    ngDefaultControl
    [ngModel]="date()"
    (ngModelChange)="onChangeDate($event)"
    [showIcon]="true"
    iconDisplay="input"
    [dateFormat]="dateFormat()"
    [showTime]="false"
    [selectOtherMonths]="true"
    [showWeek]="true"
    [minDate]="minDate"
    appendTo="body"
    styleClass="w-full font-medium"
    [placeholder]="placeholder()"
    [firstDayOfWeek]="1"
    inputStyleClass="border-none bg-transparent text-inherit p-0 shadow-none"
>
</p-datePicker>
