import { Component, Input } from '@angular/core';
import { faBuildingMagnifyingGlass, faRightLeftLarge } from '@fortawesome/pro-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import {
    SearchCategorySelectComponent
} from '../../../../core/components/inputs/search-category-select/search-category-select.component';
import {
    SearchNavButtonComponent
} from '../../../../search/components/search-nav-button/search-nav-button.component';
import {
    SearchSupplyDateSelectComponent
} from '../../../../search/supply/components/date-select/search-supply-date-select.component';

@Component({
    selector: 'homepage-map-controls',
    standalone: true,
    imports: [
        SearchCategorySelectComponent,
        SearchNavButtonComponent,
        SearchSupplyDateSelectComponent,
        RouterLink,
        NgClass
    ],
    templateUrl: './homepage-map-controls.component.html'
})
export class HomepageMapControlsComponent {
    @Input() colorMode: 'light' | 'dark' = 'light';

    protected readonly faRightLeftLarge = faRightLeftLarge;
    protected readonly faBars = faBars;
    protected readonly faBuildingMagnifyingGlass = faBuildingMagnifyingGlass;
}
